/* styles.css */
.title-container,
.description-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

@media (max-width: 768px) {
  .title-container,
  .description-container {
    justify-content: flex-start;
    text-align: left;
    font-size: 16px;
  }
}
