.animate {
  animation: ease-in fadeIn 1s;
}

a { cursor: pointer; }
a:hover,a.hover { text-decoration: underline; }

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.page-block {
  margin: 10px auto;
  max-width: 768px;
}

.page-block--sm {
  margin: 0 auto;
  max-width: 768px;
}

@media screen and (max-width: 767px) {
  .page-block {
    margin: 30px;
  }
}

.img-block {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

@media screen and (max-width: 767px) {
  .img-block {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}
